import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import FunProject from './FunProject';
import Playground from './Playground';
import Navigation from './Navigation';
import Gomoku from './components/games/gomoku/Gomoku';
import TextToSpeech from './components/projects/TextToSpeech/TextToSpeech';
import WordCount from './components/games/WordCount/WordCount';
import Contact from './Contact';
import CalculatorPage from './components/games/calculator/CalculatorPage';
import IELTSWriting from './components/projects/IELTSWriting/IELTSWriting'; // 새로 추가

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const showNavigation = !['/gomoku', '/text-to-speech', '/calculator', '/word-count', '/ielts-writing'].includes(location.pathname);

  return (
    <>
      {showNavigation && <Navigation />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/fun-project" element={<FunProject />} />
        <Route path="/playground" element={<Playground />} />
        <Route path="/gomoku" element={<Gomoku />} />
        <Route path="/text-to-speech" element={<TextToSpeech />} />
        <Route path="/word-count" element={<WordCount />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/calculator" element={<CalculatorPage />} />
        <Route path="/ielts-writing" element={<IELTSWriting />} /> // 새로 추가
      </Routes>
    </>
  );
}

export default App;
