import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav className="top-nav">
      <div className="nav-container">
        <ul className="nav-menu">
          <li><NavLink to="/" end>Cloud Tech Note</NavLink></li>
          <li><NavLink to="/fun-project">Fun Project</NavLink></li>
          <li><NavLink to="/playground">Playground</NavLink></li> {/* /fun-game을 /playground로 변경 */}
          <li><NavLink to="/contact">Contact Us</NavLink></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
